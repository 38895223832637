.selectorInput-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
}
    .selectorInput-container-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        width: 100%;
        height: 23px;
    }
        .selectorInput-container-labels-input {
            margin-left: 4px;
            text-decoration: none;
            border: none;
            outline: none;
            background-color: transparent;
            width: 100%;
        }
    .selectorInput-container-list {
        width: 100%;
        flex-direction: column;
        background: transparent;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border: 1px solid var(--primary500);
        height: fit-content;
        max-height: 100px;
        overflow-y: scroll;
        z-index: 2;
    }
        .selectorInput-container-list-item {
            width: 100%;
            padding: 10px 0;
            justify-content: space-between;
            align-items: center;
            display: flex;
            flex-direction: row;
            background: var(--white);
            cursor: pointer;
        }
            .selectorInput-container-list-item-text {
                color: var(--gray700);
                margin-left: 12px;
            }
        .selectorInput-container-list-item:hover {
            background-color: var(--gray100);
        }